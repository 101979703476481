import { Component, Vue } from 'vue-property-decorator'
import { Group, User } from '@/Models'
import http from '@/services'
import Search from '@/components/Search/index.vue'
import DepartmentForm from '@/components/DepartmentForm/index.vue'
import modalComponent from '@/components/ModalComponent/ModalComponent.vue'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import FiltersButton from '@/components/FiltersButton/index.vue'
import menuModule from '@/store/menu'
@Component({
  components: {
    Search,
    DepartmentForm,
    ModalDelete,
    modalComponent,
    FiltersButton,
  },
})
export default class DepartmentList extends Vue {
  action = 'list'

  delete_modal_active = false
  objEdit: User = new User()
  headers: Array<any> = [
    {
      field: 'name',
      label: this.$t('department.Name'),
      width: '40',
    },
  ]
  data: Array<User> = []
  userRoles: Array<any> = []

  mounted() {
    this.getUsers()
  }

  filterItems(items: any) {
    this.data = items
  }

  add() {
    this.action = 'create'
  }
  edit(user: User) {
    this.objEdit = user
    //this.objEdit.permissions = this.getAction(user)
    this.action = 'edit'
  }

  async getUsers() {
    menuModule.setLoding(true)
    try {
      const res = await http.get('panel/department/')
      if (!res.data) throw new Error(res.data)
      console.log(res)
      this.data = res.data
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
    menuModule.setLoding(false)
  }

  showActiveDeleteModal(user: User) {
    this.objEdit = user
    this.delete_modal_active = !this.delete_modal_active
  }

  async deleteUsers() {
    try {
      await http.delete(`panel/department/${this.objEdit.id}/`)
      this.data = this.data.filter((a: User) => a.id !== this.objEdit.id)
      this.getUsers()
      this.closeModal(false)
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }
  closeModal(valor: boolean) {
    this.delete_modal_active = valor
  }

  closeForm() {
    this.action = 'list'
    this.getUsers()
  }
}
