import { Component, Vue, Prop } from 'vue-property-decorator'
import http from '@/services/http'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Department, IItem } from '@/Models'
// import multiSelect from "@/components/multiSelect.vue"

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    // multiSelect,
  },
})
export default class deparmentModal extends Vue {
  @Prop({ required: false })
  departmentReplace!: Department
  @Prop({ required: false })
  action!: string

  loading = false
  title = 'Create new deparment'
  color = 'has-background-info'
  iconLeft = 'account-plus'
  confirmationIcon = 'plus'
  confirmationButtonTitle = 'Add deparment'
  department: Department = new Department()
  groups: Array<IItem> = []
  // deparmentgroups: IItem[] = []
  deparmentgroups: any = 0
  emit = 'create-deparment'
  showPass = true
  isLoading = false

  mounted() {
    // this.getGroups()
    if (this.action == 'edit') {
      this.showPass = false
      // this.deparmentReplace.groups.forEach((item: any) => {
      // 	this.deparmentgroups.push({
      // 		value: item.id,
      // 		text: item.name,
      // 	})
      // })
      //this.deparmentgroups = this.deparmentReplace.groups[0]
      // this.emit = 'edit-deparment'
      // this.title = 'User edit'
      // this.color = 'has-background-primary'
      // this.iconLeft = 'account-edit'
      // this.confirmationIcon = 'border-color'
      // this.confirmationButtonTitle = 'Edit'
      Object.assign(this.department, this.departmentReplace)
    } else {
      this.department = new Department()
    }
  }

  confirmation() {
    this.isLoading = true
    this.department.company_id = 1
    if (this.action == 'edit') {
      this.updateDepartment(this.department)
    } else {
      this.createDepartments(this.department)
    }
  }

  async createDepartments(deparment: Department) {
    try {
      await http.post('panel/department/', this.department)
      this.$buefy.toast.open({
        message: 'Departamento agregado',
        type: 'is-success',
      })
      this.isLoading = false
      this.close()
    } catch (error) {
      console.error(error)
      this.isLoading = false
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }
  async updateDepartment(department: Department) {
    const departmentUpdate = department
    try {
      await http.put(
        `panel/department/${departmentUpdate.id}/`,
        departmentUpdate,
      )
      this.$buefy.toast.open({
        message: 'Departamento actualizado',
        type: 'is-success',
      })

      this.isLoading = false
      this.close()
    } catch (error) {
      console.error(error)
      this.isLoading = false
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }

  close() {
    this.$emit('closeForm')
  }
}
